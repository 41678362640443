import { ReactElement } from "react";

export interface ISectionTitleProps {
    title: ReactElement;
    customClassName?: string;
}

export const SectionTitle = (props: ISectionTitleProps) => {
    return <div className={`section-title__wrapper ${props.customClassName}`}>
        <h2 className={`section-title__title`}>{props.title}</h2>
    </div>;
}