import { ReactElement } from "react";
import tireTracks from '../images/tire-track.png';

export interface IBannerProps {
    backgroundImage?: any;
    title: ReactElement;
    customClass?: string;
    // onShowChange: (value: boolean) => void;
}

export const Banner = (props: IBannerProps) => {
    return <section className={`banner ${props.customClass ? props.customClass : ''}`}>
        <div className="banner__background" style={{backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : ''}}>
            <div className="banner__content-wrapper">
                <h1 className="banner__title">
                    {props.title}
                </h1>
            </div>

            <img src={tireTracks} alt="Ślady opon terenowych" className="banner__tire-tracks" />
        </div>
    </section>;
}