import { useEffect } from "react";
import { Footer } from "../../components/footer";
import { Navbar } from "../../components/navbar";
import AppConfig from "../../utils/appconfig";
import { scrollBodyToTop } from "../../utils/utils";
import { SectionTitle } from "../../components/sectionTitle";
import { LinkButton } from "../../components/linkButton";

import bgImageSection from '../../images/hard-adv-4.jpg';

const currentPage: string = 'hard-adv-store';

export function HardAdvStore() {
  useEffect(() => {
    document.title = `Hard Adv Store - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper hard-adv-store__content-wrapper">
        <div className="hard-adv-store__text">
          <SectionTitle title={<>Hard Adv <span className="text-color--primary">Store</span></>} customClassName={'section-title__wrapper--home'} />

          <p><a href="https://hardshop.hard-adv.com/index.php/moje-konto/" className="text-color--primary">Załóż konto w sklepie</a> i otrzymaj <span className="text-weight--superBold">10% rabatu</span> na cały nieprzeceniony asortyment z kodem <span className="text-color--primary text-weight--superBold">#HARDADV</span>!</p>

          <LinkButton buttonText={'PRZEJDŹ DO SKLEPU'} linkTo={'https://hardshop.hard-adv.com/'} customClassName="home-section-1__button-link-wrapper hard-adv-store__action-button" />
        </div>
      </div>

      <section className={`o-wydarzeniu-section-2`}>
        <div className="o-wydarzeniu-section-2__background" style={{backgroundImage: bgImageSection ? `url(${bgImageSection})` : '', minHeight: '380px'}}></div>
      </section>

      <Footer currentPage={currentPage} />
    </div>
  );
}
