import { ReactElement, useEffect, useState } from "react";
import { SectionTitle } from "./sectionTitle";

import tireTracks from '../images/tire-track.png';
import AppConfig from "../utils/appconfig";

export interface IContactSectionProps {
    backgroundImage?: any;
    title: ReactElement;
    customClass?: string;
    // onShowChange: (value: boolean) => void;
}

export const ContactSection = (props: IContactSectionProps) => {
    const defaultButtonText: string = "Wyślij";
    const loadingButtonText: string = "Trwa wysyłanie";

    const [formData, setFormData] = useState({
        name: "" as string,
        email: "" as string,
        subject: "" as string,
        message: "" as string,
    });
    const [response, setResponse] = useState<any>('');
    const [responseSuccess, setResponseSuccess] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>(defaultButtonText);
    const [asyncActionInProgress, setAsyncActionInProgress] = useState<boolean>(false);
    const [buttonTextInterval, setButtonTextInterval] = useState<any>();

    useEffect(() => {
        if(asyncActionInProgress === true) {
            let tempButtonText: string = buttonText;

            setButtonTextInterval(setInterval(() => {
                if(tempButtonText === defaultButtonText || tempButtonText === `${loadingButtonText}...`) {
                    setButtonText(loadingButtonText);
                    tempButtonText = loadingButtonText;
                } else {
                    setButtonText(tempButtonText + '.');
                    tempButtonText = tempButtonText + '.';
                }
            }, 250));
        } else {
            clearInterval(buttonTextInterval);
        }
        // eslint-disable-next-line
    }, [asyncActionInProgress]);
    
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setAsyncActionInProgress(true);
        setButtonText(loadingButtonText);
        setResponse('');
        setResponseSuccess(false);
        
        const form = new FormData();

        form.append("name", formData.name);
        form.append("email", formData.email);
        form.append("subject", formData.subject);
        form.append("message", formData.message);
        
        await fetch(`${AppConfig.baseUrl}/sendContactFormMessage.php`, {
            method: "POST",
            body: form,
        })
        .then((response) => response.text())
        .then((data: any) => {
            setResponse(data);
            setButtonText(defaultButtonText);

            if(data === 'Wiadomość została wysłana pomyślnie.') {
                setResponseSuccess(true);
            }
        }).catch((error: any) => {
            console.error("Wystąpił błąd: ", error);
            setButtonText(defaultButtonText);
            setResponse(`Wystąpił błąd: ${error}`);
            setResponseSuccess(false);
        });

        setAsyncActionInProgress(false);
    };
    
    return <section id="contact-section" className={`contact-section ${props.customClass ? props.customClass : ''}`}>
        <div className="contact-section__background" style={{backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : ''}}>
            <div className="contact-section__content-wrapper">
                <SectionTitle title={props.title} customClassName={'section-title__wrapper'} />

                <div className="contact-section__content-wrapper--inner">
                    <div className="contact-section__form-wrapper">
                        <form onSubmit={handleSubmit} className="contact-section__form">
                            <input type="text" name="name" placeholder="Imię i nazwisko" required onChange={handleInputChange} />
                            <input type="email" name="email" placeholder="Adres mailowy" required onChange={handleInputChange} />
                            <input type="text" name="subject" placeholder="Temat" onChange={handleInputChange} />
                            <textarea placeholder="Treść" name="message" required onChange={handleInputChange} />

                            {responseSuccess !== true && 
                                <div className="contact-section__form-button--wrapper">
                                    <button className={`contact-section__form-button ${asyncActionInProgress ? 'contact-section__form-button--wide' : ''}`}
                                        disabled={asyncActionInProgress || responseSuccess} type="submit"
                                    >
                                        {buttonText}
                                    </button>
                                </div>
                            }
                            
                            {!!response &&
                                <p className={`contact-section__form-response ${responseSuccess ? 'contact-section__form-response--success' : 'contact-section__form-response--error'}`}>
                                    {response}
                                </p>
                            }
                        </form>
                    </div>

                    <div className="contact-section__text-wrapper">
                        <h3><span className="text-color--primary">ORGANIZATOR</span></h3>
                        <a href="mailto:info@hard-adv.com">INFO@HARD-ADV.COM</a>
                    </div>
                </div>

                <img src={tireTracks} alt="Ślady opon terenowych" className="contact-section__tire-tracks" loading="lazy" />
            </div>
        </div>
    </section>;
}