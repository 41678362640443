import AppConfig from '../../utils/appconfig';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { useEffect } from 'react';
import { SectionTitle } from '../../components/sectionTitle';
import { scrollBodyToTop } from '../../utils/utils';

const currentPage: string = 'faq';

export function FAQ() {
  useEffect(() => {
    document.title = `FAQ - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper faq__content-wrapper">
        <div className="faq__text">
          <SectionTitle title={<><span className="text-color--primary">Kiedy</span> odbędzie się impreza?</>} customClassName={'section-title__wrapper--home'} />

          <p>HardAdv edycja III odbyła się w terminie 29.05.2024 - 02.06.2024.</p>
          <p>Dokładna data HardAdv edycja IV nie jest jeszcze znana.</p>
        </div>

        <div className="faq__text">
          <SectionTitle title={<><span className="text-color--primary">Gdzie</span> odbędzie się impreza?</>} customClassName={'section-title__wrapper--home'} />

          <p>Nasza impreza odbędzie się w malowniczym górskim terenie Beskidu Żywieckiego.</p>
        </div>

        <div className="faq__text">
          <SectionTitle title={<><span className="text-color--primary">Kto</span> może wziąć udział?</>} customClassName={'section-title__wrapper--home'} />

          <p>W ramach wydarzenia zdecydowaliśmy podzielić uczestników na trzy grupy w zależności od umiejętności i doświadczenia w jeździe off-road, dlatego każdy niezależnie od poziomu swoich umiejętności znajdzie odpowiednie dla siebie trasy.</p>
          <br />
          <p>Podział na grupy poziomowe ma na celu zapewnienie bezpieczeństwa i odpowiedniego wyzwania dla każdego uczestnika. Jako organizatorzy musimy dostosować trudność tras dla każdej grupy, aby zapewnić optymalne warunki dla wszystkich uczestników.</p>
        </div>
      </div>

      <Footer currentPage={currentPage} />
    </div>
  );
}
