import AppConfig from '../../utils/appconfig';
import { useEffect } from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { SectionTitle } from '../../components/sectionTitle';
import { scrollBodyToTop } from '../../utils/utils';

const currentPage: string = 'polityka-prywatnosci';

export function PolitykaPrywatnosci() {
  useEffect(() => {
    document.title = `Polityka prywatności - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper polityka-prywatnosci__content-wrapper">
        <div className="polityka-prywatnosci__text">
          <SectionTitle title={<>POLITYKA <span className="text-color--primary">PRYWATNOŚCI</span></>} customClassName={'section-title__wrapper--home'} />

          <p>{`Data wejścia w życie: 22.10.2023

            Niniejsza Polityka Prywatności wyjaśnia, jak HARD ADV Spółka z ograniczoną odpowiedzialnością zbiera, przechowuje, przetwarza, udostępnia i chroni dane osobowe użytkowników tej strony internetowej. Prosimy o uważne zapoznanie się z tym dokumentem, aby zrozumieć, jakie informacje gromadzimy i w jaki sposób są one wykorzystywane.

            1. Rodzaje Danych Osobowych

            Możemy zbierać następujące rodzaje danych osobowych od użytkowników:

            Imię i nazwisko
            Adres email
            Adres IP

            2. Sposób Gromadzenia Danych

            Dane osobowe mogą być zbierane w następujący sposób:

            Przy użyciu plików cookies lub podobnych technologii
            Poprzez formularze kontaktowe
            Poprzez analizę zachowań użytkowników na stronie

            3. Cel Przetwarzania Danych

            Gromadzone dane osobowe są przetwarzane w celu:

            Kontaktu z użytkownikami w odpowiedzi na zapytania i pytania
            Poprawy jakości usług i dostosowania ich do indywidualnych potrzeb
            Analizy statystyk i trendów korzystania ze strony

            4. Udostępnianie Danych Osobowych

            Dane osobowe użytkowników nie są udostępniane osobom trzecim bez zgody użytkownika, chyba że jest to wymagane przez prawo lub jest to niezbędne do realizacji usług oferowanych przez HARD ADV Spółka z ograniczoną odpowiedzialnością.

            5. Bezpieczeństwo Danych

            Podjęliśmy odpowiednie środki ostrożności, aby chronić dane osobowe użytkowników przed nieuprawnionym dostępem, utratą lub nieuprawnionym ujawnieniem. Nasza strona internetowa wykorzystuje standardowe technologie ochrony danych, takie jak szyfrowanie SSL.

            6. Prawa Użytkowników

            Użytkownicy mają prawo dostępu do swoich danych osobowych, ich poprawiania, usuwania, ograniczania przetwarzania, przenoszenia i sprzeciwiania się przetwarzaniu. W celu realizacji tych praw prosimy o kontakt pod adresem INFO@HARD-ADV.COM.

            7. Zmiany w Polityce Prywatności

            Polityka Prywatności może ulegać zmianom. Wszelkie zmiany zostaną opublikowane na tej stronie, a data wejścia w życie zostanie zaktualizowana.

            Niniejsza Polityka Prywatności została wprowadzona dnia 22.10.2023.

            Jeśli masz jakiekolwiek pytania dotyczące Polityki Prywatności, prosimy o kontakt pod adresem INFO@HARD-ADV.COM.`}
          </p>
        </div>
      </div>

      <Footer currentPage={currentPage} />
    </div>
  );
}
