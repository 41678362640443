import { useEffect } from "react";
import { Footer } from "../../components/footer";
import { Navbar } from "../../components/navbar";
import AppConfig from "../../utils/appconfig";
import { scrollBodyToTop } from "../../utils/utils";
import { SectionTitle } from "../../components/sectionTitle";
import { LinkButton } from "../../components/linkButton";
import { ContactSection } from "../../components/contactSection";

import homepageContactSectionBackground from '../../images/hard-adv-5.jpg';

const currentPage: string = 'wyjazdy-adventure';

export function WyjazdyAdventure() {
  useEffect(() => {
    document.title = `Wyjazdy Adventure - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper wyjazdy-adventure__content-wrapper">
        <div className="wyjazdy-adventure__text">
          <SectionTitle title={<>Szczegóły planowanych <span className="text-color--primary">wyjazdów</span>:</>} customClassName={'section-title__wrapper--home'} />

          <div>
            <p>1. Nowa Zelandia</p>

            <LinkButton buttonText={'OFERTA - LISTOPAD 2024'} target="_blank" rel="noreferrer" linkTo={'https://hard-adv.com/static/media/HardADV_oferta.pdf'} customClassName="wyjazdy-adventure__action-button" />
          </div>
        </div>
      </div>

      <ContactSection title={<>FORMULARZ KONTAKTOWY</>} backgroundImage={homepageContactSectionBackground} />
      
      <Footer currentPage={currentPage} />
    </div>
  );
}
