import AppConfig from '../../utils/appconfig';
import { useEffect } from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { SectionTitle } from '../../components/sectionTitle';
import { scrollBodyToTop } from '../../utils/utils';

const currentPage: string = 'polityka-plikow-cookie';

export function PolitykaPlikowCookie() {
  useEffect(() => {
    document.title = `Polityka plików cookie - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper polityka-plikow-cookie__content-wrapper">
        <div className="polityka-plikow-cookie__text">
          <SectionTitle title={<>POLITYKA <span className="text-color--primary">PLIKÓW COOKIE</span></>} customClassName={'section-title__wrapper--home'} />

          <p>{`Data wejścia w życie: 22.10.2023

            Niniejsza Polityka Plików Cookie wyjaśnia, jak HARD ADV Spółka z ograniczoną odpowiedzialnością używa plików cookie i innych technologii śledzenia podczas korzystania z naszej strony internetowej. Prosimy o uważne zapoznanie się z tym dokumentem, aby zrozumieć, w jaki sposób gromadzimy i wykorzystujemy informacje za pomocą plików cookie.

            1. Czym są Pliki Cookie?

            Pliki cookie to małe pliki tekstowe przechowywane na urządzeniu użytkownika podczas korzystania z naszej strony internetowej. Pliki cookie zawierają informacje, które pozwalają nam rozpoznać użytkowników, dostosowywać treści i usługi oraz analizować zachowanie użytkowników.

            2. Rodzaje Plików Cookie

            Używamy różnych rodzajów plików cookie na naszej stronie internetowej, w tym:

            Pliki cookie niezbędne do działania strony, które umożliwiają korzystanie z podstawowych funkcji strony.
            Pliki cookie analityczne, które pomagają nam śledzić statystyki korzystania ze strony.

            3. Cel Używania Plików Cookie

            Pliki cookie są używane w celu:

            Poprawy działania strony i dostosowania jej do potrzeb użytkowników.
            Analizy zachowań użytkowników i zbierania statystyk.
            Ułatwienia nawigacji po stronie.

            4. Zarządzanie Plikami Cookie

            Użytkownicy mają możliwość zarządzania plikami cookie poprzez ustawienia swojej przeglądarki internetowej. Można wyłączyć lub usunąć pliki cookie z urządzenia. Należy pamiętać, że wyłączenie plików cookie może wpłynąć na działanie niektórych funkcji strony.

            5. Zewnętrzne Serwisy i Pliki Cookie

            Nasza strona internetowa może korzystać z usług zewnętrznych dostawców, takich jak Google Analytics, które również mogą używać plików cookie. Niniejsza Polityka Plików Cookie nie obejmuje plików cookie tych dostawców. Prosimy o zapoznanie się z ich własnymi politykami prywatności i plików cookie.

            6. Zmiany w Polityce Plików Cookie

            Polityka Plików Cookie może ulegać zmianom. Wszelkie zmiany zostaną opublikowane na tej stronie, a data wejścia w życie zostanie zaktualizowana.

            Niniejsza Polityka Plików Cookie została wprowadzona dnia 22.10.2023.

            Jeśli masz jakiekolwiek pytania dotyczące Polityki Plików Cookie, prosimy o kontakt pod adresem INFO@HARD-ADV.COM.`}
          </p>
        </div>
      </div>

      <Footer currentPage={currentPage} />
    </div>
  );
}
