import AppConfig from '../../utils/appconfig';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { useEffect } from 'react';
import { SectionTitle } from '../../components/sectionTitle';
import { RozkladJazdyBgImageSection } from './components/rozkladJazdyBgImageSection';
import { scrollBodyToTop } from '../../utils/utils';

import endurak from '../../images/endurak666.png';
import bgImageSection from '../../images/hard-adv-4.jpg';
import tireTracks from '../../images/tire-track.png';

const currentPage: string = 'rozklad-jazdy';

export function RozkladJazdy() {
  useEffect(() => {
    document.title = `Rozkład jazdy - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="rozklad-jazdy-section-1">
        <div className="rozklad-jazdy-section-1__text">
          <SectionTitle title={<>GDZIE I <span className="text-color--primary">KIEDY</span>?</>} customClassName={'section-title__wrapper--home'} />

          <h3>TA NIEZWYKŁA PRZYGODA ODBĘDZIE SIĘ W DNIACH 29/05 - 02/06 2024</h3>
          
          <p>Podczas wydarzenia będziemy eksplorować wymagający teren górski, który zapewni Wam niezapomniane doświadczenia i adrenalinę. Nasza impreza odbędzie się w malowniczym górskim <span className='text-weight--bold'>terenie Beskidu Żywieckiego</span>, który jest doskonałym miejscem dla wymagającego motocyklisty.</p>
        </div>
        
        <img src={endurak} alt="Motocyklista w stroju enduro idący po błocie" className="rozklad-jazdy-section-1__image" />
      </div>

      <RozkladJazdyBgImageSection backgroundImage={bgImageSection} />

      <div className="content-wrapper rozklad-jazdy__content-wrapper">
        <div className="rozklad-jazdy-section-3">
          <SectionTitle title={<>INFORMACJE <span className="text-color--primary">ORGANIZACYJNE</span></>} customClassName={'section-title__wrapper--home'} />

          <p>Aby zarezerwować swoje miejsce na imprezie, prosimy o zapisanie się przez aplikacje <a href='https://raceduck.app/event/HARD%20ADV%20WEEKEND%202024' target="_blank" rel="noreferrer">Raceduck</a>, wybrać klasę oraz typ noclegu wpłacić zadatek tylko 1000 PLN, który gwarantuje wam uczestnictwo.</p>
          
          <p>Pozostałą kwotę trzeba wpłacić na konto HARD ADV w całości lub w ratach, nie później niż <span className='text-weight--bold'>1 Marca 2024r.</span>, w tytule płatności wpisując swoje imię i nazwisko. (Wyślemy mail z instrukcjami po zapisaniu się) W przypadku wycofania się z uczestnictwa zadatek nie podlega zwrotowi.</p>
          
          <p className='text-weight--bold'>Liczba miejsc jest ograniczona, dlatego zachęcamy do jak najszybszego zgłoszenia.</p>
          
          <p>Całkowity Koszt Imprezy Rancho/Kazik/Aldona 2500 PLN</p>
          
          <p>Całkowity Koszt Imprezy Kamping 2100 PLN Własny Kamper/Namiot</p>

          <p>Osoby towarzyszące naszym uczestnikom w cenie Ośrodek 1700 PLN / Kamping 1300 PLN</p>

          <p>Dodatkowo każdy uczestnik otrzyma ubezpieczenie, pakiet startowy koszulka+(niespodzianka) oraz weźmie udział w losowaniu wartościowych nagród na zakończenie imprezy.</p>
          
          <p>Wyżywienie wliczone w cenę.</p>

          <p className='text-weight--bold'>W niedzielę istnieje możliwość późniejszego wykwaterowania.</p>

          <p>Codziennie na ośrodku będzie czynna restauracja, gdzie uczestnicy będą mogli zamówić dodatkowe dania z karty.</p>
        </div>
      </div>

      <div className="rozklad-jazdy__tire-tracks--wrapper">
        <img src={tireTracks} alt="Ślady opon terenowych" className="rozklad-jazdy__tire-tracks" loading="lazy" />
      </div>

      <Footer currentPage={currentPage} />
    </div>
  );
}
