import { Link } from "react-router-dom";

export interface ILinkButtonProps {
    linkTo: string;
    buttonText: string;
    customStyle?: any;
    customButtonStyle?: any;
    customClassName?: string;
    target?: string;
    whiteIcon?: boolean;
    rel?: string;
    disabled?: boolean;
    // onShowChange: (value: boolean) => void;
}

export const LinkButton = (props: ILinkButtonProps) => {
    const { linkTo, buttonText } = props;

    const buttonContent = <button className="custom-button" style={props.customButtonStyle}>
        {buttonText}
    </button>;

    return (!!props.target && props.target === '_blank') ?
        <a href={linkTo} target={props.target} rel={props.rel} className={`custom-button__link-wrapper ${props.disabled ? 'custom-button__link-wrapper--disabled' : ''} ${props.customClassName}`}
            style={props.customStyle}
        >
            {buttonContent}
        </a>
        :
        <Link to={linkTo} target={props.target} className={`custom-button__link-wrapper ${props.disabled ? 'custom-button__link-wrapper--disabled' : ''} ${props.customClassName}`}
            style={props.customStyle}
        >
            {buttonContent}
        </Link>;
}