import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './scss/main.scss';

import { HomePage } from './pages/Home';
import { ZostanSponsorem } from './pages/ZostanSponsorem';
import { Kontakt } from './pages/Kontakt';
import { NotFound } from './pages/404';
import { PolitykaPrywatnosci } from './pages/PolitykaPrywatnosci';
import { FAQ } from './pages/FAQ';
import { RozkladJazdy } from './pages/RozkladJazdy';
import { OWydarzeniu } from './pages/OWydarzeniu';
import { PolitykaPlikowCookie } from './pages/PolitykaPlikowCookie';
import { WyjazdyAdventure } from './pages/WyjazdyAdventure';
import { HardAdvStore } from './pages/HardAdvStore';
import { Galeria } from './pages/Galeria';

export function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/o-wydarzeniu",
      element: <OWydarzeniu />,
    },
    {
      path: "/galeria",
      element: <Galeria />,
    },
    {
      path: "/rozklad-jazdy",
      element: <RozkladJazdy />,
    },
    {
      path: "/zostan-sponsorem",
      element: <ZostanSponsorem />,
    },
    {
      path: "/wyjazdy-adventure",
      element: <WyjazdyAdventure />,
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
    {
      path: "/kontakt",
      element: <Kontakt />,
    },
    {
      path: "/polityka-prywatnosci",
      element: <PolitykaPrywatnosci />,
    },
    {
      path: "/polityka-plikow-cookie",
      element: <PolitykaPlikowCookie />,
    },
    {
      path: "/hard-adv-store",
      element: <HardAdvStore />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;