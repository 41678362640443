import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';
import { SectionTitle } from '../../components/sectionTitle';
import { PartnerSection } from '../../components/partnerSection';
import { LinkButton } from '../../components/linkButton';
import { scrollBodyToTop } from '../../utils/utils';

import tireTracks from '../../images/tire-track.png';

const currentPage: string = 'zostan-sponsorem';

export function ZostanSponsorem() {
  useEffect(() => {
    document.title = `Zostań sponsorem - ${AppConfig.baseTabTitle}`;
    
    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper zostan-sponsorem__content-wrapper">
        <div className="zostan-sponsorem__text">
          <SectionTitle title={<>ZOSTAŃ NASZYM <span className="text-color--primary">SPONSOREM</span></>} customClassName={'section-title__wrapper--home'} />

          <p>Dołącz do naszej pasjonującej społeczności i stań się sponsorem niezapomnianego wydarzenia! Twoja wsparcie pomoże nam stworzyć odpowiednią atmosferę, zapewnić emocjonujące chwile i umożliwić uczestnikom przeżycie niezapomnianych momentów na dwóch kółkach.</p>
          
          <LinkButton buttonText={'DOŁĄCZ DO NAS'} linkTo={'/kontakt'} customClassName="home-section-1__button-link-wrapper" />
        </div>

        <PartnerSection customClassName='zostan-sponsorem__grid-wrapper' />

        <div className="zostan-sponsorem__tire-tracks--wrapper">
          <img src={tireTracks} alt="Ślady opon terenowych" className="zostan-sponsorem__tire-tracks" loading="lazy" />
        </div>
      </div>

      <Footer currentPage={currentPage} />
    </div>
  );
}
