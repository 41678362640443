import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';
import { ContactSection } from '../../components/contactSection';
import { SectionTitle } from '../../components/sectionTitle';
import { scrollBodyToTop } from '../../utils/utils';

import homepageContactSectionBackground from '../../images/hard-adv-5.jpg';

const currentPage: string = 'kontakt';

export function Kontakt() {
  useEffect(() => {
    document.title = `Kontakt - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper kontakt__content-wrapper">
        <SectionTitle title={<>DANE <span className="text-color--primary">ORGANIZATORA</span></>} customClassName={'section-title__wrapper--home'} />

        <h3>HARD ADV Spółka z ograniczoną odpowiedzialnością</h3>
        <br />
        <h3>34-300, Żywiec</h3>

        <div className='kontakt__data-wrapper'>
          <div className='kontakt__data-box'>
            <h2>KRS</h2>
            <p>0001016982</p>
          </div>

          <div className='kontakt__data-box'>
            <h2>NIP</h2>
            <p>5532582399</p>
          </div>

          <div className='kontakt__data-box'>
            <h2>REGON</h2>
            <p>524341190</p>
          </div>
        </div>
      </div>

      <ContactSection title={<>FORMULARZ KONTAKTOWY</>} backgroundImage={homepageContactSectionBackground} />

      <Footer currentPage={currentPage} />
    </div>
  );
}
