import { SectionTitle } from '../../../components/sectionTitle';
import { TextTile } from '../../../components/textTile';
import tireTracks from '../../../images/tire-track.png';

export interface IOWydarzeniuBgImageSectionProps {
    backgroundImage?: any;
}

export const OWydarzeniuBgImageSection = (props: IOWydarzeniuBgImageSectionProps) => {
    return <section className={`o-wydarzeniu-section-2`}>
        <div className="o-wydarzeniu-section-2__background" style={{backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : ''}}>
            <div className="o-wydarzeniu-section-2__content-wrapper">
                <img src={tireTracks} alt="Ślady opon terenowych" className="o-wydarzeniu-section-2__tire-tracks" loading="lazy" />
                <img src={tireTracks} alt="Ślady opon terenowych" className="o-wydarzeniu-section-2__tire-tracks o-wydarzeniu-section-2__tire-tracks--second" loading="lazy" />

                <TextTile title={<SectionTitle title={<>Grupa <span className="text-color--primary">EASY:</span></>} />} 
                    text={<p>Ta grupa jest dla motocyklistów, którzy dopiero zaczynają swoją przygodę z jazdą off-road. , drogi szutrowe czy utwardzone bez udziwnień. Turystyczne trasy dla grupy Beginners są łatwiejsze i bardziej przystępne, aby umożliwić uczestnikom zdobycie podstawowych umiejętności i pewności siebie.</p>} 
                />

                <TextTile title={<SectionTitle title={<>Grupa <span className="text-color--primary">MEDIUM:</span></>} />} 
                    text={<p>Ta grupa jest dla motocyklistów o umiarkowanym doświadczeniu w jeździe off-road. Osoby w tej grupie mają już doświadczenie w pokonywaniu różnych terenów, ale mogą potrzebować dalszego rozwoju swoich umiejętności. Trasy dla grupy Advanced są mniej ekstremalne niż dla grupy Extreme, ale nadal wymagające i interesujące.</p>} 
                />

                <TextTile title={<SectionTitle title={<>Grupa <span className="text-color--primary">HARD:</span></>} />} 
                    text={<p>Ta grupa jest przeznaczona dla bardzo doświadczonych motocyklistów, którzy mają dużą wprawę w jeździe off-road. Są to osoby, które czują się pewnie na trudnym terenie, potrafią pokonywać ekstremalne przeszkody i radzą sobie w nieprzewidywalnych warunkach. W grupie Extreme trasy są najbardziej wymagające i skomplikowane.</p>} 
                />
            </div>
        </div>
    </section>;
}