import { PartnerTile } from "./partnerTile";

import partnerCamelAdv from '../images/logos/camel-adv.png';
// import partnerStecSuspension from '../images/logos/stec-suspension.png';
import partnerHeed from '../images/logos/heed.jpg';
// import partnerRebelhorn from '../images/logos/rebelhorn.png';
import partnerEmpro from '../images/logos/empro.png';
// import partnerOdinsberg from '../images/logos/odinsberg.png';
// import partnerGarda from '../images/logos/garda.png';
import partnerGmoto from '../images/logos/gmoto.png';
// import partnerEnduristanUbezpieczenia from '../images/logos/enduristan-ubezpieczenia.jpg';
import partnerRaceduck from '../images/logos/raceduck.jpg';
// import partnerDominator from '../images/logos/dominator.png';
// import partnerRedMamut from '../images/logos/red-mamut.png';
import partnerLoneRider from '../images/logos/lone-rider.jpg';
// import partnerRanchoAdama from '../images/logos/rancho-adama.png';
// import partnerOneEleven from '../images/logos/one-eleven.jpg';
import partnerTouratechPolska from '../images/logos/touratech-polska.png';
import partnerLibertyMotors from '../images/logos/liberty-motors.png';
import partnerXrepo from '../images/logos/xrepo.png';
// import partnerPoligrafiksXPinline from '../images/logos/poligrafiks-x-pinline.png';
// import partnerKtmSklep from '../images/logos/ktm-sklep.jpg';
// import partnerRats from '../images/logos/rats.png';
// import partnerBrowarGlubczyce from '../images/logos/browar-glubczyce.jpg';
import partnerCardo from '../images/logos/cardo.png';
import partnerMountreme from '../images/logos/mountreme.png';
import partnerAdventureBikesCoNZ from '../images/logos/adv-bikes-co-nz.jpg';
import partnerOlekMotocykle from '../images/logos/olek-motocykle.png';
import partnerDavca from '../images/logos/davca.png';
import partnerMiastoZywiec from '../images/logos/miasto-zywiec.png';
import partnerFox from '../images/logos/fox.png';
import partnerNTK from '../images/logos/ntk.png';
import partnerNGK from '../images/logos/ngk.png';
import partnerMitas from '../images/logos/mitas.png';
import partnerLeatt from '../images/logos/leatt.png';
import partnerQuadLock from '../images/logos/quad-lock.png';
import partnerMotoMyczki from '../images/logos/moto-myczki-team.png';
import partnerRevit from '../images/logos/revit.png';
import partnerHondaKarlik from '../images/logos/honda-karlik.png';
import partnerYamaha from '../images/logos/yamaha.png';
import partnerRepsol from '../images/logos/repsol.png';
import partnerMucOff from '../images/logos/muc-off.png';
import partnerPoloptic from '../images/logos/poloptic.png';
import partnerDopeMirrors from '../images/logos/dope-mirrors.png';
import partnerRedline from '../images/logos/redline.png';
import partnerNexxHelmets from '../images/logos/nexx-helmets.png';
import partnerDefender from '../images/logos/defender.png';
import partnerGarmin from '../images/logos/garmin.png';
import partnerYakkExp from '../images/logos/yakk-exp.png';
import partnerPolaris from '../images/logos/polaris.png';
import partnerPrzystanekSycylia from '../images/logos/przystanek-sycylia.jpg';
import partnerDzikaBryka from '../images/logos/dzika-bryka.png';
import partnerRadeGarage from '../images/logos/rade-garage.png';

export interface IPartnerSectionProps {
    customClassName?: string;
}

export const PartnerSection = (props: IPartnerSectionProps) => {
    const {customClassName} = props;

    return <div className={`partner-tile__grid-wrapper ${customClassName}`}>
        <PartnerTile imgSrc={partnerAdventureBikesCoNZ} imgAlt={'ADVENTURE BIKES COMPANY NEW ZEALAND'} title={'ADVENTURE BIKES COMPANY NEW ZEALAND'} />
        {/* <PartnerTile imgSrc={partnerBrowarGlubczyce} imgAlt={'BROWAR GŁUBCZYCE'} title={'BROWAR GŁUBCZYCE'} /> */}
        <PartnerTile imgSrc={partnerCamelAdv} imgAlt={'CAMEL ADV'} title={'CAMEL ADV'} />
        <PartnerTile imgSrc={partnerCardo} imgAlt={'CARDO'} title={'CARDO'} />
        <PartnerTile imgSrc={partnerDavca} imgAlt={'DAVCA'} title={'DAVCA'} />
        <PartnerTile imgSrc={partnerDefender} imgAlt={'DEFENDER AKCESORIA MOTOCYKLOWE'} title={'DEFENDER AKCESORIA MOTOCYKLOWE'} customClassName='partner-tile__img--medium' />
        {/* <PartnerTile imgSrc={partnerDominator} imgAlt={'DOMINATOR'} title={'DOMINATOR'} /> */}
        <PartnerTile imgSrc={partnerDopeMirrors} imgAlt={'DOPE MIRRORS'} title={'DOPE MIRRORS'} />
        <PartnerTile imgSrc={partnerDzikaBryka} imgAlt={'DZIKA BRYKA'} title={'DZIKA BRYKA'} />
        <PartnerTile imgSrc={partnerEmpro} imgAlt={'EMPRO'} title={'EMPRO'} />
        {/* <PartnerTile imgSrc={partnerEnduristanUbezpieczenia} imgAlt={'ENDURISTAN UBEZPIECZENIA'} title={'ENDURISTAN UBEZPIECZENIA'} /> */}
        <PartnerTile imgSrc={partnerFox} imgAlt={'FOX'} title={'FOX'} />
        {/* <PartnerTile imgSrc={partnerGarda} imgAlt={'GARDA'} title={'GARDA'} /> */}
        <PartnerTile imgSrc={partnerGarmin} imgAlt={'GARMIN'} title={'GARMIN'} />
        <PartnerTile imgSrc={partnerGmoto} imgAlt={'GMOTO'} title={'GMOTO'} customClassName='partner-tile__img--medium' />
        <PartnerTile imgSrc={partnerHeed} imgAlt={'HEED'} title={'HEED'} />
        <PartnerTile imgSrc={partnerHondaKarlik} imgAlt={'HONDA KARLIK'} title={'HONDA KARLIK'} customClassName='partner-tile__img--wide' />
        {/* <PartnerTile imgSrc={partnerKtmSklep} imgAlt={'KTM SKLEP'} title={'KTM SKLEP'} /> */}
        <PartnerTile imgSrc={partnerLeatt} imgAlt={'LEATT'} title={'LEATT'} />
        <PartnerTile imgSrc={partnerLibertyMotors} imgAlt={'LIBERTY MOTORS'} title={'LIBERTY MOTORS'} />
        <PartnerTile imgSrc={partnerLoneRider} imgAlt={'LONE RIDER'} title={'LONE RIDER'} customClassName='partner-tile__img--medium' />
        <PartnerTile imgSrc={partnerMiastoZywiec} imgAlt={'MIASTO ŻYWIEC'} title={'MIASTO ŻYWIEC'} />
        <PartnerTile imgSrc={partnerMitas} imgAlt={'MITAS'} title={'MITAS'} />
        <PartnerTile imgSrc={partnerMotoMyczki} imgAlt={'MOTO MYCZKI TEAM'} title={'MOTO MYCZKI TEAM'} />
        <PartnerTile imgSrc={partnerMountreme} imgAlt={'MOUNTREME'} title={'MOUNTREME'} customClassName='partner-tile__img--wide' />
        <PartnerTile imgSrc={partnerMucOff} imgAlt={'MUC-OFF'} title={'MUC-OFF'} />
        <PartnerTile imgSrc={partnerNGK} imgAlt={'NGK'} title={'NGK'} />
        <PartnerTile imgSrc={partnerNTK} imgAlt={'NTK'} title={'NTK'} />
        <PartnerTile imgSrc={partnerNexxHelmets} imgAlt={'NEXX HELMETS'} title={'NEXX HELMETS'} customClassName='partner-tile__img--wide' />
        {/* <PartnerTile imgSrc={partnerOdinsberg} imgAlt={'ODINSBERG'} title={'ODINSBERG'} /> */}
        <PartnerTile imgSrc={partnerOlekMotocykle} imgAlt={'OLEK MOTOCYKLE'} title={'OLEK MOTOCYKLE'} customClassName='partner-tile__img--medium' />
        {/* <PartnerTile imgSrc={partnerOneEleven} imgAlt={'ONE ELEVEN'} title={'ONE ELEVEN'} /> */}
        {/* <PartnerTile imgSrc={partnerPoligrafiksXPinline} imgAlt={'POLIGRAFIKS X PINLINE'} title={'POLIGRAFIKS X PINLINE'} customClassName='partner-tile__img--wide' /> */}
        <PartnerTile imgSrc={partnerPolaris} imgAlt={'POLARIS'} title={'POLARIS'} />
        <PartnerTile imgSrc={partnerPoloptic} imgAlt={'POLOPTIC SALONY OPTYCZNE'} title={'POLOPTIC SALONY OPTYCZNE'} />
        <PartnerTile imgSrc={partnerPrzystanekSycylia} imgAlt={'PRZYSTANEK SYCYLIA'} title={'PRZYSTANEK SYCYLIA'} customClassName='partner-tile__img--medium' />
        <PartnerTile imgSrc={partnerQuadLock} imgAlt={'QUAD LOCK'} title={'QUAD LOCK'} />
        <PartnerTile imgSrc={partnerRaceduck} imgAlt={'RACEDUCK'} title={'RACEDUCK'} />
        <PartnerTile imgSrc={partnerRadeGarage} imgAlt={'RADE GARAGE'} title={'RADE GARAGE'} customClassName='partner-tile__img--medium' />
        {/* <PartnerTile imgSrc={partnerRanchoAdama} imgAlt={'RANCHO ADAMA'} title={'RANCHO ADAMA'} /> */}
        {/* <PartnerTile imgSrc={partnerRats} imgAlt={'RATs'} title={'REGIONAL ADVENTURE TRAILS'} /> */}
        {/* <PartnerTile imgSrc={partnerRebelhorn} imgAlt={'REBELHORN'} title={'REBELHORN'} /> */}
        {/* <PartnerTile imgSrc={partnerRedMamut} imgAlt={'RED MAMUT'} title={'RED MAMUT'} /> */}
        <PartnerTile imgSrc={partnerRedline} imgAlt={'REDLINE'} title={'REDLINE'} customClassName='partner-tile__img--medium' />
        <PartnerTile imgSrc={partnerRepsol} imgAlt={'REPSOL'} title={'REPSOL'} />
        <PartnerTile imgSrc={partnerRevit} imgAlt={'REVIT'} title={'REVIT'} customClassName='partner-tile__img--medium' />
        {/* <PartnerTile imgSrc={partnerStecSuspension} imgAlt={'STEC SUSPENSION'} title={'STEC SUSPENSION'} /> */}
        <PartnerTile imgSrc={partnerTouratechPolska} imgAlt={'TOURATECH POLSKA'} title={'TOURATECH POLSKA'} />
        <PartnerTile imgSrc={partnerXrepo} imgAlt={'XREPO'} title={'XREPO'} />
        <PartnerTile imgSrc={partnerYakkExp} imgAlt={'YAKK EXP'} title={'YAKK EXP'} />
        <PartnerTile imgSrc={partnerYamaha} imgAlt={'YAMAHA'} title={'YAMAHA'} customClassName='partner-tile__img--medium' />
    </div>;
}