import { Footer } from "../../components/footer";
import { Navbar } from "../../components/navbar";
import tireTracks from '../../images/tire-track.png';

export function NotFound() {
  return (
    <div className="App theme-light">
      <Navbar currentPage={'home'} />

      <div className="content-wrapper not-found__content-wrapper">
        <h3 className="not-found__h3">404 - nie znaleziono strony!</h3>

        <img src={tireTracks} alt="Ślady opon terenowych" className="not-found__tire-tracks not-found__tire-tracks--1" loading="lazy" />
        <img src={tireTracks} alt="Ślady opon terenowych" className="not-found__tire-tracks not-found__tire-tracks--2" loading="lazy" />
      </div>

      <Footer currentPage={'home'} />
    </div>
  );
}
