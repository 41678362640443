import { Link } from "react-router-dom";

export interface IPartnerTileProps {
    title: string;
    imgSrc: string;
    imgAlt: string;
    linkTo?: string;
    target?: string;
    customClassName?: string;
}

export const PartnerTile = (props: IPartnerTileProps) => {
    const {title, imgSrc, imgAlt, linkTo, target, customClassName} = props;

    return (!!linkTo) ? 
            (!!target && target === '_blank') ?
                <a href={linkTo} target={target} title={title} className={`partner-tile__link-wrapper ${customClassName}`}>
                    <img src={imgSrc} alt={imgAlt} className={`partner-tile__img`} loading="lazy" />
                </a>
                :
                <Link to={linkTo!} target={target} title={title} className={`partner-tile__link-wrapper ${customClassName}`}>
                    <img src={imgSrc} alt={imgAlt} className={`partner-tile__img`}  loading="lazy" />
                </Link>
        :
        <img src={imgSrc} alt={imgAlt} title={title} className={`partner-tile__img ${customClassName}`} loading="lazy" />;
}