import AppConfig from '../../utils/appconfig';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { useEffect } from 'react';
import { SectionTitle } from '../../components/sectionTitle';
import { scrollBodyToTop } from '../../utils/utils';
import { LinkButton } from '../../components/linkButton';
import ImageGallery from "react-image-gallery";

import galleryImg1 from '../../images/gallery/2024/1.jpg';
import galleryImg2 from '../../images/gallery/2024/2.jpg';
import galleryImg3 from '../../images/gallery/2024/3.jpg';
import galleryImg4 from '../../images/gallery/2024/4.jpg';
import galleryImg5 from '../../images/gallery/2024/5.jpg';
import galleryImg6 from '../../images/gallery/2024/6.jpg';
import galleryImg7 from '../../images/gallery/2024/7.jpg';
import galleryImg8 from '../../images/gallery/2024/8.jpg';
import galleryImg9 from '../../images/gallery/2024/9.jpg';
import galleryImg10 from '../../images/gallery/2024/10.jpg';
import galleryImg11 from '../../images/gallery/2024/11.jpg';
import galleryImg12 from '../../images/gallery/2024/12.jpg';

const images = [
  { original: galleryImg1 },
  { original: galleryImg2 },
  { original: galleryImg3 },
  { original: galleryImg4 },
  { original: galleryImg5 },
  { original: galleryImg6 },
  { original: galleryImg7 },
  { original: galleryImg8 },
  { original: galleryImg9 },
  { original: galleryImg10 },
  { original: galleryImg11 },
  { original: galleryImg12 },
];

const currentPage: string = 'galeria';

export function Galeria() {
  useEffect(() => {
    document.title = `Galeria - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper galeria__content-wrapper">
        <div className="galeria__text">
          <SectionTitle title={<>Hard Adv Weekend <span className="text-color--primary">2024</span></>} customClassName={'section-title__wrapper--home'} />

          <p>Pełna galeria zdjęć z wydarzenia dostępna jest na platformie <span className="text-weight--superBold">Dysk Google</span>:</p>

          <div className="galeria__buttons-wrapper">
            <LinkButton buttonText={'DZIEŃ 1'} linkTo={'https://drive.google.com/drive/folders/1RWz-pdwwjeyMjG_u88h8i5qIyjGtJYCZ?usp=drive_link'} target={'_blank'} customClassName="home-section-1__button-link-wrapper galeria__action-button" />
            <LinkButton buttonText={'DZIEŃ 2'} linkTo={'https://drive.google.com/drive/folders/1jeg9_pUpbV5K070ejDyzkF9h01uI8idS?usp=drive_link'} target={'_blank'} customClassName="home-section-1__button-link-wrapper galeria__action-button" />
            <LinkButton buttonText={'DZIEŃ 3'} linkTo={'https://drive.google.com/drive/folders/1oO2Gy6hdiVt3kzwPElvJ8JkixvexIIr_?usp=drive_link'} target={'_blank'} customClassName="home-section-1__button-link-wrapper galeria__action-button" />
          </div>
        </div>
      </div>

      <div style={{marginBottom: '50px'}}>
        <ImageGallery items={images} showThumbnails={false} showBullets={true} lazyLoad={true} autoPlay={true} />
      </div>

      <Footer currentPage={currentPage} />
    </div>
  );
}
