import { Link } from "react-router-dom";
import logo from '../images/hardadv-logo.png';
import { useState } from "react";

export interface INavbarProps {
    currentPage: string;
}

export const Navbar = (props: INavbarProps) => {
    const { currentPage } = props;
    const [navMobileActive, setNavMobileActive] = useState<boolean>(false);

    const handleMobileIconClick: any = () => {
        setNavMobileActive(!navMobileActive);
    };

    return <header>
        <nav>
            <div className="content-wrapper">
                <div id="nav-mobile-icon" className={`nav-mobile__icon ${navMobileActive && 'nav-mobile__icon--active'}`}
                    onClick={() => handleMobileIconClick()}    
                >
                    &#9776;
                </div>
                <Link to="/">
                    <img src={logo} title="Strona główna" className="nav__logo" alt="#HardAdv logo" />
                </Link>
                <div className="nav-desktop">
                    <Link to="/o-wydarzeniu" className={`nav__item ${currentPage === 'o-wydarzeniu' && 'nav__item--active'}`}>O wydarzeniu</Link>
                    <Link to="/galeria" className={`nav__item ${currentPage === 'galeria' && 'nav__item--active'}`}>Galeria 2024</Link>
                    <Link to="/zostan-sponsorem" className={`nav__item ${currentPage === 'zostan-sponsorem' && 'nav__item--active'}`}>Zostań sponsorem</Link>
                    <Link to="/wyjazdy-adventure" className={`nav__item ${currentPage === 'wyjazdy-adventure' && 'nav__item--active'}`}>Wyjazdy Adventure</Link>
                    <Link to="/hard-adv-store" className={`nav__item ${currentPage === 'hard-adv-store' && 'nav__item--active'}`}>Hard Adv Store</Link>
                    <Link to="/kontakt" className={`nav__item ${currentPage === 'kontakt' && 'nav__item--active'}`}>Kontakt</Link>
                </div>

                <div className={`nav-mobile ${navMobileActive && 'nav-mobile--active'}`}>
                    <Link to="/o-wydarzeniu" className={`nav__item ${currentPage === 'o-wydarzeniu' && 'nav__item--active'}`}>O wydarzeniu</Link>
                    <Link to="/galeria" className={`nav__item ${currentPage === 'galeria' && 'nav__item--active'}`}>Galeria 2024</Link>
                    <Link to="/zostan-sponsorem" className={`nav__item ${currentPage === 'zostan-sponsorem' && 'nav__item--active'}`}>Zostań sponsorem</Link>
                    <Link to="/wyjazdy-adventure" className={`nav__item ${currentPage === 'wyjazdy-adventure' && 'nav__item--active'}`}>Wyjazdy Adventure</Link>
                    <Link to="/hard-adv-store" className={`nav__item ${currentPage === 'hard-adv-store' && 'nav__item--active'}`}>Hard Adv Store</Link>
                    <Link to="/kontakt" className={`nav__item ${currentPage === 'kontakt' && 'nav__item--active'}`}>Kontakt</Link>
                </div>
            </div>
        </nav>
    </header>;
}