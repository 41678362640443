import { SectionTitle } from '../../../components/sectionTitle';
import { TextTile } from '../../../components/textTile';

import tireTracks from '../../../images/tire-track.png';

export interface IRozkladJazdyBgImageSectionProps {
    backgroundImage?: any;
}

export const RozkladJazdyBgImageSection = (props: IRozkladJazdyBgImageSectionProps) => {
    return <section className={`rozklad-jazdy-section-2`}>
        <div className="rozklad-jazdy-section-2__background" style={{backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : ''}}>
            <div className="rozklad-jazdy-section-2__content-wrapper">
                <img src={tireTracks} alt="Ślady opon terenowych" className="rozklad-jazdy-section-2__tire-tracks" loading="lazy" />
                <img src={tireTracks} alt="Ślady opon terenowych" className="rozklad-jazdy-section-2__tire-tracks rozklad-jazdy-section-2__tire-tracks--second" loading="lazy" />

                <SectionTitle title={<>ROZKŁAD JAZDY</>} customClassName={'section-title__wrapper--home'} />

                <div className="rozklad-jazdy-section-2__text-tiles-wrapper">
                    <div className="rozklad-jazdy-section-2__text-tiles-wrapper--pair">
                        <TextTile title={<SectionTitle title={<>ŚRODA</>} />} subTitle={`29.05.2024`} customClassName={'text-tile__rozklad-jazdy'} 
                            text={<><p>Zakwaterowanie od godziny 14:00</p><p>19.00 Kolacja w formie bufetu szwedzkiego wydawana do godziny 23.00</p></>} 
                        />

                        <TextTile title={<SectionTitle title={<>CZWARTEK</>} />} subTitle={`30.05.2024`} customClassName={'text-tile__rozklad-jazdy'} 
                            text={<><p>8.00 Śniadanie w formie bufetu szwedzkiego</p><p>18.00 Obiadokolacja w formie bufetu szwedzkiego</p></>} 
                        />
                    </div>

                    <TextTile title={<SectionTitle title={<>PIĄTEK</>} />} subTitle={`31.05.2024`} customClassName={'text-tile__rozklad-jazdy text-tile__rozklad-jazdy--mobile'} 
                        text={<><p>8.00 Śniadanie w formie bufetu szwedzkiego</p><p>16.00 Obiadokolacja w formie bufetu szwedzkiego</p><p>19.00 Kolacja w formie bufetu szwedzkiego, Zimna płyta</p><p>22.30 Druga gorąca kolacja, napoje bezalkoholowe</p></>} 
                    />

                    <div className="rozklad-jazdy-section-2__text-tiles-wrapper--pair">
                        <TextTile title={<SectionTitle title={<>SOBOTA</>} />} subTitle={`01.06.2024`} customClassName={'text-tile__rozklad-jazdy'} 
                            text={<><p>8.00 Śniadanie w formie bufetu szwedzkiego</p><p>18.00 Obiadokolacja w formie bufetu szwedzkiego</p></>} 
                        />

                        <TextTile title={<SectionTitle title={<>NIEDZIELA</>} />} subTitle={`02.06.2024`} customClassName={'text-tile__rozklad-jazdy'} 
                            text={<><p>9.00 Śniadanie w formie bufetu szwedzkiego</p><p>Wyjazd uczestników - wykwaterowanie do godziny 11.00</p></>} 
                        />
                    </div>
                </div>
                
                <TextTile title={<SectionTitle title={<>PIĄTEK</>} />} subTitle={`31.05.2024`} customClassName={'text-tile__rozklad-jazdy--desktop'} 
                    text={<><p>8.00 Śniadanie w formie bufetu szwedzkiego</p><p>16.00 Obiadokolacja w formie bufetu szwedzkiego</p><p>19.00 Kolacja w formie bufetu szwedzkiego, Zimna płyta</p><p>22.30 Druga gorąca kolacja, napoje bezalkoholowe</p></>} 
                />
            </div>
        </div>
    </section>;
}