import { ReactElement } from "react";

export interface ITextTileProps {
    title: ReactElement;
    subTitle?: string;
    text: ReactElement;
    customClassName?: string;
}

export const TextTile = (props: ITextTileProps) => {
    const {title, subTitle, text, customClassName} = props;

    return <div className={`text-tile__wrapper ${customClassName}`}>
        <div className="text-tile__content-wrapper">
            {title}

            {!!subTitle &&
                <h3>{subTitle}</h3>
            }

            {text}
        </div>
    </div>;
}