import { Link } from 'react-router-dom';
import facebookLogo from '../images/facebook-logo.png';
import circleLogo from '../images/circle-logo.png';
import hardAdvLogo from '../images/hardadv-logo.png';

export interface IFooterProps {
    currentPage: string;
}

export const Footer = (props: IFooterProps) => {
    const { currentPage } = props;

    return <footer className={`footer`}>
        <div className="footer-content">
            <div className="footer-section footer-section__social-media">
                <h3 className="footer-section__title">SOCIAL MEDIA</h3>
                <a href="https://www.facebook.com/profile.php?id=100078660143173" target="_blank" rel="noreferrer">
                    <img src={facebookLogo} title="HardAdv Facebook" className="footer__social-media-logo" alt="Facebook logo" loading="lazy" />
                </a>
                <a href="https://hard-adv-society.circle.so/" target="_blank" rel="noreferrer">
                    <img src={circleLogo} title="HardAdv Society (Circle app)" className="footer__social-media-logo" alt="Circle app logo" loading="lazy" />
                </a>
            </div>

            <div className="footer-section footer-section__info">
                <h3 className="footer-section__title">INFORMACJE</h3>
                <Link to="/o-wydarzeniu" className={`footer__item ${currentPage === 'o-wydarzeniu' && 'footer__item--active'}`}>O wydarzeniu</Link>
                <Link to="/galeria" className={`footer__item ${currentPage === 'galeria' && 'footer__item--active'}`}>Galeria 2024</Link>
                <Link to="/rozklad-jazdy" className={`footer__item ${currentPage === 'rozklad-jazdy' && 'footer__item--active'}`}>Rozkład jazdy - archiwum 2024</Link>
                <Link to="/zostan-sponsorem" className={`footer__item ${currentPage === 'zostan-sponsorem' && 'footer__item--active'}`}>Zostań sponsorem</Link>
                <Link to="/wyjazdy-adventure" className={`footer__item ${currentPage === 'wyjazdy-adventure' && 'footer__item--active'}`}>Wyjazdy Adventure</Link>
            </div>

            <div className="footer-section footer-section__legal-info">
                <h3 className="footer-section__title">INFORMACJE PRAWNE</h3>
                <Link to="/polityka-prywatnosci" className={`footer__item ${currentPage === 'polityka-prywatnosci' && 'footer__item--active'}`}>Polityka prywatności</Link>
                <Link to="/polityka-plikow-cookie" className={`footer__item ${currentPage === 'polityka-plikow-cookie' && 'footer__item--active'}`}>Polityka plików cookie</Link>
            </div>

            <div className="footer-section footer-section__support">
                <h3 className="footer-section__title">WSPARCIE</h3>
                <Link to="/faq" className={`footer__item ${currentPage === 'faq' && 'footer__item--active'}`}>FAQ</Link>
                <Link to="/hard-adv-store" className={`footer__item ${currentPage === 'hard-adv-store' && 'footer__item--active'}`}>Hard Adv Store</Link>
                <Link to="/kontakt" className={`footer__item ${currentPage === 'kontakt' && 'footer__item--active'}`}>Kontakt</Link>
            </div>
        </div>

        <Link to="/" className='footer__logo'>
            <img src={hardAdvLogo} title="Strona główna" className="nav__logo" alt="#HardAdv logo" loading="lazy" />
        </Link>

        <p className='footer__author'>Realizacja: <a href="https://www.instagram.com/_croppek_/" target="_blank" rel="noreferrer">Bartosz "Kropek" Kropidłowski</a></p>
    </footer>
}